<template>
  <div v-if="form_in_request">
    <Form
      :initial-values="initialValues"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <q-card-section class="row form-inputs-content justify-between">
        <div
          v-for="(structure, i) in structureForm"
          :key="i"
          class="full-width row no-wrap"
        >
          <Field
            v-if="onResetCheck(structure)"
            :name="structure.name"
            v-slot="{ errorMessage, value, field }"
          >
            <q-select
              v-if="structure.type === 'select'"
              filled
              outlined
              use-input
              clearable
              bottom-slots
              v-bind="field"
              :model-value="value"
              :label="structure.label"
              :error="!!errorMessage"
              :options="structure.options"
              :error-message="errorMessage"
              :dense="$q.platform.is.mobile"
              @update:model-value="resetInputBank"
              @filter="structure.filter"
              class="full-width"
            >
              <template v-slot:before>
                <q-icon :name="structure.icon" />
              </template>
            </q-select>
            <q-input
              v-else
              filled
              clearable
              bottom-slots
              class="q-mb-sm full-width"
              :stack-label="structure['stack-label']"
              v-bind="field"
              :model-value="value"
              :type="structure.type"
              :mask="structure.mask"
              :label="structure.label"
              :error="!!errorMessage"
              :error-message="errorMessage"
              :dense="$q.platform.is.mobile"
              :counter="!!structure.maxlength"
              :maxlength="structure.maxlength"
            >
              <template v-slot:before>
                <q-icon :name="structure.icon" />
              </template>
            </q-input>
          </Field>
        </div>
      </q-card-section>
      <q-card-section class="row q-mt-none q-pt-none">
        <q-btn
          outline
          color="grey"
          type="submit"
          :loading="in_request"
          :disable="in_request"
          :label="$t('global.save')"
          class="full-width bg-grey-10 q-mt-sm q-pa-sm q-pr-lg q-pl-lg"
        />
      </q-card-section>
    </Form>
  </div>
  <div v-else>
    <account-type-bank-skeleton />
  </div>
</template>

<script>
import {
  build,
  labels,
  getStructure,
  getMappedFields,
  buildInitialValues,
  getFormValuesChanged,
} from "@/shared/form-presets";
import AccountTypeBankSkeleton from "./skeletons/AccountTypeBankSkeleton.vue";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, reactive, nextTick, onMounted } from "vue";
import { getFieldsDefinitionsBank } from "./setup";
import { Field, Form } from "vee-validate";

const { useGetters, useActions } = createNamespacedHelpers("account");

export default {
  name: "AccountAddress",

  components: {
    Field,
    Form,
    AccountTypeBankSkeleton,
  },

  setup() {
    const in_request = ref(false),
      form_in_request = ref(false);

    const fieldsDefinitions = getFieldsDefinitionsBank();

    const { banks, bank_account } = useGetters({
        banks: "getBanksToOptions",
        bank_account: "getPartnerBankAccount",
      }),
      {
        setBankFilterKey,
        updateUserPartnerBank,
        fetchBanksListAction,
      } = useActions([
        "updateUserPartnerBank",
        "setBankFilterKey",
        "fetchBanksListAction",
      ]);

    const structureForm = reactive(
      getStructure(Object.keys(fieldsDefinitions))
    );
    structureForm.bank.options = banks;
    structureForm.bank.onReset = ref(false);

    const onResetCheck = (structure) =>
        structure.name === "bank" ? !structure.onReset : true,
      resetInputBank = (value) => {
        if (value && value.toString().length > 0) return void 0;
        structureForm.bank.onReset = true;
        nextTick(() => (structureForm.bank.onReset = false));
      };

    structureForm.bank.filter = (val, update) =>
      update(() => setBankFilterKey(val));

    let initialValues = buildInitialValues(
      fieldsDefinitions,
      structureForm,
      bank_account.value
    );

    const onSubmit = (values) => {
      const payload = getFormValuesChanged(
        fieldsDefinitions,
        initialValues,
        values
      );

      if (Object.keys(payload).length === 0) {
        notifySuccess("actions.bank_account_changed_success");
        return;
      }

      in_request.value = true;
      updateUserPartnerBank({ ...bank_account.value, ...payload })
        .then(() => {
          initialValues = values;
          notifySuccess("actions.bank_account_changed_success");
        })
        .catch(({ errors }) => {
          notifyError("actions.bank_account_on_changed_error");
          handleErrors(errors);
        })
        .finally(() => (in_request.value = false));
    };

    const handleErrors = (errors) => {
      if (errors) for (const error of Object.values(errors)) notifyError(error);
    };

    onMounted(() =>
      fetchBanksListAction()
        .then(
          () =>
            (initialValues.bank = structureForm.bank.options.find(
              (e) => e.value == bank_account.value.bank_code
            ))
        )
        .finally(() => (form_in_request.value = true))
    );

    return {
      banks,
      labels,
      bank_account,
      in_request,
      initialValues,
      structureForm,
      form_in_request,
      onSubmit,
      onResetCheck,
      resetInputBank,
      schema: build(getMappedFields(Object.keys(fieldsDefinitions))),
    };
  },
};
</script>
